import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-elgin-illinois.png'
import image0 from "../../images/cities/scale-model-of-lords-park-zoo-in-elgin-illinois.png"
import image1 from "../../images/cities/scale-model-of-santa's-village-azoosment-park-in-elgin-illinois.png"
import image2 from "../../images/cities/scale-model-of-explore-elgin-area-in-elgin-illinois.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Elgin'
            state='Illinois'
            image={image}
            lat='42.0354084'
            lon='-88.28256679999998'
            attractions={ [{"name": "Lords Park Zoo", "vicinity": "325 Hiawatha Dr, Elgin", "types": ["zoo", "point_of_interest", "establishment"], "lat": 42.040613, "lng": -88.26041470000001}, {"name": "Santa's Village Azoosment Park", "vicinity": "601 Dundee Ave, East Dundee", "types": ["amusement_park", "zoo", "point_of_interest", "establishment"], "lat": 42.091242, "lng": -88.26029}, {"name": "Explore Elgin Area", "vicinity": "60 S Grove Ave #1, Elgin", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 42.03584, "lng": -88.28350230000001}] }
            attractionImages={ {"Lords Park Zoo":image0,"Santa's Village Azoosment Park":image1,"Explore Elgin Area":image2,} }
       />);
  }
}